<template>
    <div
        :class="`col-12 pr--card--article pr-shadow-sm ${!article.style.only_image ? 'p-3' : 'p-0'} rounded card mb-3 rounded-sm-bottom pr-bg-white`"
    >
        <div v-if="!article.style.message" class="row">
            <FrontendArticleCardImage :article="article" />
            <div v-if="!article.style.only_image" :class="`${article.title !== null ? 'col-12 col-md-8' : 'col-12'}`">
                <div class="card-body p-0 h-100">
                    <div class="d-flex align-items-start justify-content-between flex-column h-100">
                        <div class="w-100">
                            <FrontendArticleCardTitle :article="article" />
                            <FrontendArticleCardPublishedAt :article="article" />
                            <FrontendArticleCardDescription :article="article" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="article.style.message" class="row">
            <div class="col-12">
                <div class="card-body p-0 h-100">
                    <div class="d-flex align-items-start justify-content-between flex-column h-100">
                        <div class="w-100">
                            <FrontendArticleCardPublishedAt :article="article" />
                            <FrontendArticleCardMarkdown :article="article" class="mb-3" />
                        </div>
                    </div>
                </div>
            </div>
            <FrontendArticleCardImage v-if="article.image !== null" :article="article" />
        </div>
        <div v-if="type === 'default'" class="row">
            <div class="col-12">
                <FrontendArticleCardFooter :article="article" />
            </div>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import FrontendArticleCardDescription from '@/components/Frontend/Article/Card/FrontendArticleCardDescription.vue';
import FrontendArticleCardFooter from '@/components/Frontend/Article/Card/FrontendArticleCardFooter.vue';
import FrontendArticleCardImage from '@/components/Frontend/Article/Card/FrontendArticleCardImage.vue';
import FrontendArticleCardMarkdown from '@/components/Frontend/Article/Card/FrontendArticleCardMarkdown.vue';
import FrontendArticleCardPublishedAt from '@/components/Frontend/Article/Card/FrontendArticleCardPublishedAt.vue';
import FrontendArticleCardTitle from '@/components/Frontend/Article/Card/FrontendArticleCardTitle.vue';
import useI18nRoute from '@/hooks/i18nRoute';
import useMomentDate from '@/hooks/momentDate';

export default defineComponent({
    name: 'FrontendArticleCardLeft',
    components: {
        FrontendArticleCardMarkdown,
        FrontendArticleCardFooter,
        FrontendArticleCardDescription,
        FrontendArticleCardPublishedAt,
        FrontendArticleCardTitle,
        FrontendArticleCardImage,
    },
    directives: {
        // tippy: directive,
    },
    props: {
        article: {
            type: [Object],
            default: () => {
                return {
                    title: 'Collective #787',
                    title__highlight: undefined,
                    preview_description: 'Scroll-Driven State Transfer * The Three Cs * What’s new in CSS?',
                    preview_description__highlight: undefined,
                    published_at: 'October 20, 2023',
                    image: 'https://codrops-1f606.kxcdn.com/codrops/wp-content/uploads/2023/10/Collective787_large.jpg?x49023',
                };
            },
        },
        type: {
            type: [String],
            default: () => {
                return 'default'; // default, compact
            }
        }
    },
    setup() {
        const i18nRoute = useI18nRoute();
        const momentDate = useMomentDate();
        // showError('😱 Oh no, an error has been thrown.');
        return {
            i18nRoute,
            momentDate,
        };
    }
});
</script>
