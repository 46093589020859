<template>
    <div>
        <div v-if="article.style.linkable">
            <p
                v-if="article.title !== null"
                class="card-text"
            >
                <NuxtLink
                    :to="i18nRoute.card(article)"
                    class="link-default"
                >
                    <span v-html="article.title" />
<!--                    <BaseMarkdown :value="article.preview_description" />-->
                </NuxtLink>
            </p>
        </div>
        <div v-if="!article.style.linkable">
            <p
                v-if="article.title !== null"
                class="card-text"
            >
                <span v-html="article.title" />

<!--                <span class="markdown-container">-->
<!--                    <BaseMarkdown :value="article.preview_description" />-->
<!--&lt;!&ndash;                    <span v-if="system.server" class="d-block w-100 placeholder-wave">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="d-block placeholder w-25 mb-2" />&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="d-block placeholder w-75 mb-2" />&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="d-block placeholder w-50" />&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--                </span>-->
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">

import BaseMarkdown from '@/components/Base/Markdown/BaseMarkdown.vue';
import useI18nRoute from '@/hooks/i18nRoute';
import useMomentDate from '@/hooks/momentDate';
import { useStoreSystem } from '@/stores/system';

const i18nRoute = useI18nRoute();
const momentDate = useMomentDate();
const system = useStoreSystem();

defineProps<{
    article: object
}>();

</script>
