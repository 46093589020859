<template>
    <div class="d-flex align-items-start justify-content-between mt-3 w-100">
        <div>
            <div class="d-inline-flex mr-2">
                <NuxtLink :to="i18nRoute.group(article.group)" class="badge-icon-image">
                    <img
                        :src="article.group.image"
                        :alt="article.group.title"
                        height="20"
                        width="20"
                        class="rounded-1"
                    >
                    <span class="badge">
                        {{ article.group.title }}
                    </span>
                </NuxtLink>
            </div>
            <div
                v-for="(item, key) in article.group_categories"
                v-if="article.group_categories.length"
                :key="key"
                class="d-inline-flex mr-2"
            >
                <NuxtLink :to="i18nRoute.groupCategory(item.group, item)">
                    <span class="badge text-bg-primary">
                        {{ item.title_short }}
                    </span>
                </NuxtLink>
            </div>
        </div>
        <div class="d-inline-flex align-items-center">
            <div v-if="article.socials.links.site !== undefined" class="mr-3">
                <a
                    v-tippy
                    :href="article.socials.links.site.value"
                    :content="'Перейти на сайт'"
                    class="btn btn-icon btn-sm btn-outline-light-darken d-flex"
                    target="_blank"
                >
                    <IconFontAwesome :name="'globe'" />
                    <span class="small d-none d-sm-flex ml-2">
                        {{ article.socials.links.site.host !== null ? article.socials.links.site.host : article.socials.links.site.value }}
                    </span>
                </a>
            </div>
            <div v-if="article.author !== null && article.author.socials.links.twitter !== undefined && article.author.socials.links.twitter !== null" class="mr-3">
                <a
                    v-tippy
                    :href="article.author.socials.links.twitter"
                    :content="'Перейти к автору'"
                    class="btn btn-icon btn-sm btn-outline-light-darken d-flex"
                    target="_blank"
                >
                    <IconFontAwesome :name="'x-twitter'" />
                    <span class="small d-none d-sm-flex ml-2">
                        {{ article.author.title }}
                    </span>
                </a>
            </div>
            <div v-if="article.is_package && article.socials.links.github !== undefined" v-tippy="'Перейти в GitHub'">
                <a :href="article.socials.links.github.value" target="_blank" class="mr-3 text-dark small">
                    <IconFontAwesome :name="'github'" class="mr-1" />
                    <span v-if="article.options !== null && article.options.package_last_release_name !== undefined">{{ article.options.package_last_release_name }}</span>
                </a>
            </div>
            <div v-tippy="'Количество просмотров'" class="small">
                <IconFontAwesome :name="'eye'" class="mr-1" />
                {{ article.statistics.visits }}
            </div>

            <!--                                <button class="btn btn-icon btn-link btn-sm mr-1" role="button">-->
            <!--                                    <IconFontAwesome :name="'chevron-up'" />-->
            <!--                                </button>-->
            <!--                                <button class="btn btn-icon btn-link btn-sm" role="button">-->
            <!--                                    <IconFontAwesome :name="'chevron-down'" />-->
            <!--                                </button>-->
        </div>
    </div>
</template>

<script setup lang="ts">

import BaseDeveloper from '@/components/Base/BaseDeveloper.vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import useI18nRoute from '@/hooks/i18nRoute';
const i18nRoute = useI18nRoute();

defineProps<{
    article: object
}>();

</script>
