<template>
    <div>
        <div v-if="article.style.linkable">
            <p
                v-if="article.preview_description !== null && article.preview_description__highlight === undefined"
                class="card-text"
            >
                <NuxtLink
                    v-if="article.preview_description.length > 200"
                    :to="i18nRoute.card(article)"
                    class="link-default"
                >
                    <span v-html="article.preview_description.substring(0, 200) + ' ...'" />
                </NuxtLink>
                <NuxtLink
                    v-if="article.preview_description.length <= 200"
                    :to="i18nRoute.card(article)"
                    class="link-default"
                >
                    <span v-html="article.preview_description" />
                </NuxtLink>
            </p>
            <p v-if="article.preview_description__highlight !== undefined" class="card-text">
                <NuxtLink :to="i18nRoute.card(article)" class="link-default">
                    <span v-html="article.preview_description__highlight" />
                </NuxtLink>
            </p>
        </div>
        <div v-if="!article.style.linkable">
            <p
                v-if="article.preview_description !== null && article.preview_description__highlight === undefined"
                class="card-text"
            >
                <span v-html="article.preview_description" />
            </p>
            <p v-if="article.preview_description__highlight !== undefined" class="card-text">
                <span v-html="article.preview_description__highlight" />
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">

import useI18nRoute from '@/hooks/i18nRoute';
import useMomentDate from '@/hooks/momentDate';

const i18nRoute = useI18nRoute();
const momentDate = useMomentDate();

defineProps<{
    article: object
}>();

</script>
