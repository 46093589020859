<template>
    <p v-if="article.published_at !== null" class="card-text mb-1">
        <small class="text-body-secondary">
            {{ momentDate.latest(article.published_at) }}
        </small>
    </p>
</template>

<script setup lang="ts">

import useI18nRoute from '@/hooks/i18nRoute';
import useMomentDate from '@/hooks/momentDate';

const i18nRoute = useI18nRoute();
const momentDate = useMomentDate();

defineProps<{
    article: object
}>();

</script>
