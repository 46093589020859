<template>
    <div
        v-if="article.image !== null"
        :class="`${!article.style.full_image ? 'col-12 col-md-4 --col-image' : 'col-12'}`"
    >
        <NuxtLink v-if="article.style.linkable" :to="i18nRoute.card(article)" class="link-dark">
            <img :src="article.image" :class="`img-fluid ${article.title !== null ? '' : 'rounded'} fancybox-img`" :alt="article.title ?? ''">
        </NuxtLink>
        <div v-if="!article.style.linkable" class="link-dark">
            <img :src="article.image" :class="`img-fluid ${article.title !== null ? '' : 'rounded'} fancybox-img`" :alt="article.title ?? ''">
        </div>
    </div>
</template>

<script setup lang="ts">

import { Fancybox } from '@fancyapps/ui/dist/fancybox.esm';
import { onMounted } from 'vue';
import useI18nRoute from '@/hooks/i18nRoute';
const i18nRoute = useI18nRoute();

defineProps<{
    article: object
}>();

onMounted(() => {
    Fancybox.bind('.fancybox-img', {
        on: {
            ready: (fancybox) => {
                //console.log(`fancybox #${fancybox.id} is ready!`);
            }
        }
    });
});

</script>
