<template>
    <div>
        <h5 v-if="article.style.linkable" class="card-title mb-0">
            <NuxtLink
                v-if="article.title__highlight !== undefined"
                :to="i18nRoute.card(article)"
                class="link-default"
            >
                <span v-html="article.title__highlight" />
            </NuxtLink>
            <NuxtLink
                v-if="article.title__highlight === undefined"
                :to="i18nRoute.card(article)"
                class="link-default"
            >
                {{ article.title }}
            </NuxtLink>
            <!--                                <NuxtLink v-if="article.title__highlight !== undefined" :to="i18nRoute.card(article)" class="link-default" v-html="article.title__highlight" />-->
            <BaseDeveloper>
                <NuxtLink
                    :to="i18nRoute.editorArticle(article)"
                    target="_blank"
                    class="btn btn-sm btn-link ml-1"
                >
                    <IconFontAwesome :name="'pencil'" />
                </NuxtLink>
            </BaseDeveloper>
        </h5>
        <h5 v-if="!article.style.linkable" class="card-title mb-0">
            <span v-if="article.title__highlight !== undefined">
                <span v-html="article.title__highlight" />
            </span>
            <span v-if="article.title__highlight === undefined">
                {{ article.title }}
            </span>
        </h5>
    </div>
</template>

<script setup lang="ts">

import BaseDeveloper from '@/components/Base/BaseDeveloper.vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import useI18nRoute from '@/hooks/i18nRoute';
const i18nRoute = useI18nRoute();

defineProps<{
    article: object
}>();

</script>
